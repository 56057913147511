import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import 'leaflet/dist/leaflet.css'
import 'react-leaflet-fullscreen/dist/styles.css'


const ThirdPage = ({ data }) => {
  console.log(data);
  const state = {
    lat: 11.1950869,
    lng: 103.2821123,
    zoom: 5
  }
  const position = [state.lat, state.lng]
  if(typeof window !== 'undefined') {
    const Map = require('react-leaflet').Map
    const TileLayer = require('react-leaflet').TileLayer
    const GeoJSON = require('react-leaflet').GeoJSON
    const Marker = require('react-leaflet').Marker
    const Popup = require('react-leaflet').Popup
    const FullscreenControl = require('react-leaflet-fullscreen')
    const L = require('leaflet')
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    });
    return (
      <Layout>
        <SEO title="Page three" />
        <Map center={position} zoom={state.zoom}>
          <FullscreenControl position="topleft" />
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {data.allLocationsJson.edges.map(({ node }, index) => (
            <Marker key={index} position={[node.lat, node.lon]}>
              <Popup minWidth={200}>
                {node.type} <br /> {node.description}
                <Img fluid={data.placeholderImage.childImageSharp.fluid} />
              </Popup>
            </Marker>
          ))}
          {data.allBikeJson.edges.map(({ node }, index) => {
            console.log(node)
            return (
              <GeoJSON key={index} data={node} style={{ color: "red" }}></GeoJSON>
            )
          })}
          {data.allBoatJson.edges.map(({ node }, index) => {
            console.log(node)
            return (
              <GeoJSON
                key={index}
                data={node}
                style={{ color: "blue", dashArray: "4" }}
              ></GeoJSON>
            )
          })}
          {data.allTrainJson.edges.map(({ node }, index) => {
            console.log(node)
            return (
              <GeoJSON
                key={index}
                data={node}
                style={{ color: "green", dashArray: "4" }}
              ></GeoJSON>
            )
          })}
          {data.allBusJson.edges.map(({ node }, index) => {
            console.log(node)
            return (
              <GeoJSON
                key={index}
                data={node}
                style={{ color: "black", dashArray: "4" }}
              ></GeoJSON>
            )
          })}
        </Map>
        <Link to="/">Go back to the homepage</Link>
      </Layout>
    )
  }
  return null
}

export const query = graphql`
  query {
    allLocationsJson {
      edges {
        node {
            lat
            lon
            description
            type
        }
      }
    }
    allFile(filter: {relativePath: {glob: "data/gpx/**"}}) {
      edges {
        node {
          publicURL
          relativePath
        }
      }
    }
    allBikeJson {
      edges {
        node {
          type 
          features {
            type
            properties {
              time
              coordTimes
            }
            geometry {
              coordinates
              type
            }
          }
        }
      }
    }
    allBusJson {
      edges {
        node {
          type 
          features {
            type
            properties {
              time
              coordTimes
            }
            geometry {
              coordinates
              type
            }
          }
        }
      }
    }
    allTrainJson {
      edges {
        node {
          type 
          features {
            type
            properties {
              time
              coordTimes
            }
            geometry {
              coordinates
              type
            }
          }
        }
      }
    }
    allBoatJson {
      edges {
        node {
          type 
          features {
            type
            properties {
              time
              coordTimes
            }
            geometry {
              coordinates
              type
            }
          }
        }
      }
    }
    placeholderImage:  file(relativePath: { eq: "IMG_20180906_184842195.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`


export default ThirdPage
